var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-card" }, [
    _c(
      "div",
      { staticClass: "filter-card__main", on: { click: _vm.data.click } },
      [
        _c(
          "div",
          {
            staticClass: "filter-card__main_main",
            class: { urlImage: _vm.urlImage },
          },
          [
            _vm.urlImage
              ? _c("img", { attrs: { src: _vm.urlImage, alt: "" } })
              : _vm._e(),
            _c("div", { staticClass: "filter-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: {
                  textContent: _vm._s(
                    _vm.filter.typeOfObj ? _vm.$t("filter") : _vm.$t("Name")
                  ),
                },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.he ? _vm.filter.name : _vm.filter.name_en
                  ),
                },
              }),
            ]),
            _c("div", { staticClass: "filter-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Branches")) },
              }),
              _c("div", {
                domProps: { textContent: _vm._s(_vm.branchesText) },
              }),
            ]),
          ]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "filter-card__btm" },
      [
        _c("div"),
        _vm.data.active
          ? _c("swich-button", {
              attrs: { value: _vm.valueActive },
              on: { input: _vm.data.active },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }